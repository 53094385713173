import { isDefined } from './guards/isDefined';
import type {
  ImageFocalPointValue,
  ImageFocalPointX,
  ImageFocalPointY
} from '~/types/ImageFocalPoint';

/**
 * calculation of the focal point area in relation to the image size.
 * y coordinates are measured from the top of the image.
 */
function getFocalPointIndex(focalPoint: number, size: number | undefined) {
  return size ? Math.floor((focalPoint / size) * 3) : 1;
}

const focalPointsY: ImageFocalPointY[] = ['top', 'center', 'bottom'];

export function calculateHeightArea(
  height: number | undefined,
  focalPointHeight: number
): ImageFocalPointY | undefined {
  return focalPointsY[getFocalPointIndex(focalPointHeight, height)];
}

const focalPointsX: ImageFocalPointX[] = ['left', 'center', 'right'];

export function calculateWidthArea(
  width: number | undefined,
  focalPointWidth: number
): ImageFocalPointX | undefined {
  return focalPointsX[getFocalPointIndex(focalPointWidth, width)];
}

export function getImageArea(
  widthArea: ImageFocalPointX,
  heightArea: ImageFocalPointY
): ImageFocalPointValue {
  if (heightArea === 'center') {
    return widthArea;
  }

  if (widthArea === 'center') {
    return heightArea;
  }

  return `${heightArea}_${widthArea}`;
}

export function calculateImageArea(
  image: CalculateImageAreaIImage | undefined
): ImageFocalPointValue {
  const focalPointX = image?.fields?.focalPoint?.focalPoint?.x;
  const focalPointY = image?.fields?.focalPoint?.focalPoint?.y;

  if (
    !image?.fields?.image?.fields?.file ||
    !image.fields.image.fields.file.details?.image?.width ||
    !isDefined(focalPointX) ||
    !image.fields.image.fields.file.details?.image?.height ||
    !isDefined(focalPointY)
  ) {
    return 'center';
  }

  const widthArea = calculateWidthArea(
    image.fields.image.fields.file.details.image?.width,
    focalPointX
  );
  const heightArea = calculateHeightArea(
    image.fields.image.fields.file.details.image?.height,
    focalPointY
  );

  if (!widthArea || !heightArea) {
    return 'center';
  }

  return getImageArea(widthArea, heightArea);
}

// XXX: unify with GenerateImageObjectIImage?
export type CalculateImageAreaIImage = {
  fields: {
    image: {
      fields: {
        file: {
          details: {
            image?: {
              width: number;
              height: number;
            };
          };
        };
      };
    };
    focalPoint?: Record<string, any> | undefined;
  };
};
